<template>
  <div class="bg">
    <safe-area>
      <div class="main" style="width: 1280px;margin: 0 auto">
        <div class="content_box">
          <div class="box_left">
            <div class="box_left_user">
              <div class="user_img">
                <el-image style="width: 80px; height: 80px;border-radius: 4px"
                          src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg" fit="cover"/>
              </div>
              <div class="user_text">
                <div class="user_news">
                  {{
                    user.name
                  }}
                </div>
                <div class="user_text_bags" style="cursor:pointer;" @click="exit">{{
                    lang === 'en' ? 'Exit' : '退出登录'
                  }}
                </div>
              </div>
            </div>
            <div class="box_left_menu">
              <div class="menu" v-for="(item , i) in menu">
                {{ lang === 'en' ? item.labelEn : item.label }}
                <div class="menu_item" v-for="(child,cI) in item.children" :style="path==child.path?'color:#C81422':''"
                     @click="toPage(child.path)">
                  {{ lang === 'en' ? child.labelEn : child.label }}
                </div>
              </div>
            </div>
          </div>
          <div class="box_right">
            <div class="center_right" style="background: #fff;width: 100%">
              <slot name="content"></slot>
            </div>
          </div>
        </div>
      </div>
    </safe-area>
  </div>

</template>

<script>
import SafeArea from "@/layout/safe-area";
import {userApi} from "@/api/account";
import {getLang} from "@/utils/lang";

export default {
  name: "content-box",
  components: {SafeArea},

  props: {
    path: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      user: {},
      lang: getLang(),
      menu: [
        {
          label: '订单中心',
          labelEn: 'Order Center',
          children: [{
            label: '我的订单', path: '/user/order',
            labelEn: 'My Order',
          }, {
            label: '购物车', path: '/user/cart',
            labelEn: 'Shop Cart',
          }]
        }, {
          label: '个人中心',
          labelEn: 'Personal Center',
          children: [{
            label: '我的地址', path: '/user/address',
            labelEn: 'My Address',
          }]
        }
      ]
    }
  },
  created() {
    this.getuser()
  },
  methods: {
    getuser() {
      userApi().then(res => {
        this.user = res
      })
    },
    toPage(url) {
      if (this.path === url) return
      this.$router.push(url)
    },
    exit() {
      this.$confirm('是否要退出登录?', '退出登录', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        window.localStorage.clear()
        window.location.reload()
        this.$message({
          type: 'success',
          message: '退出成功!'
        });
      })
    }
  }
}
</script>

<style scoped>

.bg {
  background: #f3f4f5;
}

.main {
  padding: 25px 0;
}

.content_box {
  display: flex;
  padding: 20px 0;
}

.box_left {
  background: #fff;
}

.menu {
  margin-bottom: 20px;
  font-size: 16px;
}

.menu_item {
  margin-top: 10px;
  padding: 10px 0;
  border-top: 1px solid #f3f4f5;
  font-size: 14px;
  color: #00000090;
  cursor: pointer;
}

.box_left_user {
  width: 245px;
  display: flex;
  background: #7A7979;
  padding: 20px;
  box-sizing: border-box;
}

.box_right {
  width: 100%;
  margin-left: 20px;
}

.user_text {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.user_news {
  color: #fff;
  font-size: 16px;
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user_text_bags {
  width: 80px;
  border: 1px solid #DED470;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  padding: 3px;
}

.box_left_menu {
  width: 245px;
  padding: 20px;
  box-sizing: border-box;
}
</style>
