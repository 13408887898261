<template>
  <el-dialog
      :visible.sync="formShow"
      destroy-on-close
      :title="lang === 'en' ? 'Order Detail' : '订单详情'"
      width="60%"
  >
    <el-descriptions
        :column="2"
        border
        class="margin-top"
        :title="lang === 'en' ? 'Order Information' : '订单信息'"
    >
      <template #extra>

      </template>
      <el-descriptions-item>
        <template slot="label">
          <div class="cell-item">
            {{ lang === 'en' ? 'Order No' : '订单号' }}
          </div>
        </template>
        {{ detail.orderNo || '-' }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <div class="cell-item">
            {{ lang === 'en' ? 'Paid Time' : '下单时间' }}
          </div>
        </template>
        {{ detail.createdAt }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <div class="cell-item">
            {{ lang === 'en' ? 'Total Number' : '合计数量' }}
          </div>
        </template>
        {{ detail.number }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <div class="cell-item">
            {{ lang === 'en' ? 'Total Amount' : '合计金额' }}
          </div>
        </template>
        {{ lang === 'en' ? '$' + detail.amount : '￥' + detail.amount }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <div class="cell-item">
            {{ lang === 'en' ? 'Express Number' : '快递单号' }}
          </div>
        </template>
        {{ detail.expressNo === '' ? '--' : detail.expressNo }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <div class="cell-item">
            {{ lang === 'en' ? 'Payment Method' : '支付方式' }}
          </div>
        </template>
        {{ detail.payMethod === 0 ? '--' : (detail.payMethod === 1 ? '微信' : '支付宝') }}
      </el-descriptions-item>
    </el-descriptions>
    <br/>
    <el-descriptions
        :column="2"
        border
        class="margin-top"
        :title="lang === 'en' ? 'Customer Details' : '客户资料'"
    >
      <template #extra>
      </template>
      <el-descriptions-item>
        <template slot="label">
          <div class="cell-item">
            {{ lang === 'en' ? 'Customer Name' : '客户名称' }}
          </div>
        </template>
        {{ detail.postName }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <div class="cell-item">
            {{ lang === 'en' ? 'Customer Phone' : '客户号码' }}
          </div>
        </template>
        {{ detail.postPhone }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <div class="cell-item">
            {{ lang === 'en' ? 'Customer Address' : '客户地址' }}
          </div>
        </template>
        {{ detail.postAddress }}
      </el-descriptions-item>
    </el-descriptions>
    <br/>
    <el-table :data="detail.product" border stripe style="width: 100%">
      <el-table-column :label="lang === 'en' ? 'Sn' : '存货编号'" prop="erpCode" width="120"/>
      <el-table-column :label="lang === 'en' ? 'Product Pictures' : '产品图片'" width="140"
                       style="width: 80px; height: 80px">
        <template #default="scope">
          <el-image :src="scope.row.thumbnail" fit="contain"/>
        </template>
      </el-table-column>
      <el-table-column :label="lang === 'en' ? 'Product Name' : '产品名'" prop="name" v-if="lang!='en'"/>
      <el-table-column :label="lang === 'en' ? 'Product Name' : '产品英文名'" prop="nameEn" v-if="lang=='en'"/>
      <el-table-column :label="lang === 'en' ? 'Number' : '数量'" prop="number"/>
      <el-table-column :label="lang === 'en' ? 'Unit-Price' : '单价'" prop="unitAmount">
        <template #default="scope">
          {{ lang === 'en' ? '$' + scope.row.unitAmount : '￥' + scope.row.unitAmount }}
        </template>
      </el-table-column>
      <el-table-column :label="lang === 'en' ? 'Total Price' : '总价'" prop="totalAmount">
        <template #default="scope">
          {{ lang === 'en' ? '$' + scope.row.totalAmount : '￥' + scope.row.totalAmount }}
        </template>
      </el-table-column>
    </el-table>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="formShow = false">{{ lang === 'en' ? 'Close' : '关闭' }}</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {getLang} from "@/utils/lang";

export default {
  name: 'orderDetail',
  data() {
    return {
      formShow: false,
      detail: {},
      lang: getLang(),
    }
  },
  methods: {
    openForm(d) {
      this.detail = {...d}
      this.formShow = true
    }
  }
}
</script>

<style scoped>
.margin-top {
  margin-top: 20px;
}
</style>
