<template>
  <contentBox :path="path">
    <template #content>
      <div class="content">
        <div class="top_tabs">
          <div class="top_tabs_box" :style="i==tabsIndex?'color:#C81422':'color:#000'" v-for="(item , i) in tabs"
               :key="item.value" @click="onTabs(i)">
            {{ lang === 'en' ? item.labelEn : item.label }}
            <div class="top_tabs_line" v-if='i==tabsIndex'></div>
          </div>
        </div>
        <div class="toolbar">
          <!--          <el-input-->
          <!--              placeholder="请输入内容"-->
          <!--              prefix-icon="el-icon-search"-->
          <!--              clearable-->
          <!--              size="mini"-->
          <!--              v-model="search">-->
          <!--          </el-input>-->
          <!--          <el-button size="mini">搜索订单</el-button>-->
        </div>
        <div class="content_table">
          <el-table
              :data="orderList"
              stripe
              border
              :header-cell-style="{background:'#e5e5e5',color:'#000'}"
              style="width: 100%">
            <el-table-column :label="lang === 'en'?'Order Number':'订单号'" width="170">
              <template #default="scope">
                {{ scope.row.orderNo || '-' }}
              </template>
            </el-table-column>
            <el-table-column :label="lang === 'en'?'Order Time':'下单时间'" prop="createdAt" width="180"/>
            <el-table-column :label="lang === 'en'?'Number':'合计数量'" prop="number"/>
            <el-table-column :label="lang === 'en'?'Price':'合计金额'" prop="amount">
              <template #default="scope">
                {{ lang === 'en' ? '$' + scope.row.amount : '￥' + scope.row.amount }}
              </template>
            </el-table-column>
            <el-table-column :label="lang === 'en'?'Status':'订单状态'">
              <template #default="scope">
                {{ lang === 'en' ? getStatusEn(scope.row.status) : getStatus(scope.row.status) }}
              </template>
            </el-table-column>
            <el-table-column :label="lang === 'en'?'Express Number':'快递单号'" prop="expressNo" width="150">
              <template #default="scope">
                {{ scope.row.expressNo === '' ? '--' : scope.row.expressNo }}
              </template>
            </el-table-column>
            <el-table-column align="right" fixed="right" :label="lang === 'en'?'Operate':'操作'" width="300px">
              <template #default="scope">
                <el-button size="small" @click="detail(scope.row)">{{
                    lang === 'en' ? 'Detail' : '详情'
                  }}
                </el-button>
                <el-button size="small" type="danger" @click="buyOrder(scope.row.id)" v-if="scope.row.status == 0">{{
                    lang === 'en' ? 'Pay' : '支付'
                  }}
                </el-button>
                <el-button size="small" @click="delOrder(scope.row)" v-if="scope.row.status == 0">{{
                    lang === 'en' ? 'Cancel' : '取消订单'
                  }}
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <orderDetail ref="orderDetailRef"></orderDetail>
        </div>
      </div>
      <el-dialog
          :visible.sync="dialogVisibles"
          :title="lang === 'en' ? 'Scan Qr' : '请扫码支付'"
          width="30%"
      >
        <div class="qc">
          <qriously :value="baseUrl" :size="138"/>
          <div>{{
              lang === 'en' ? 'Choose Alipay or Wechat' : '请选择微信或支付宝扫码支付'
            }}
          </div>
        </div>
        <div style="display: flex;align-items: center;justify-content: center;margin-top: 10px">
          <el-button style="margin-right: 15px" @click="closeDio">{{
              lang === 'en' ? 'Cancel Order' : '取消'
            }}
          </el-button>
          <el-button type="primary" @click="getOrderStatus">{{
              lang === 'en' ? 'Have Paid' : '我已支付'
            }}
          </el-button>
        </div>

      </el-dialog>
    </template>
  </contentBox>
</template>

<script>
import contentBox from "@/components/user/content-box";
import {getOrderListApi} from "@/api/account";
import orderDetail from "@/components/user/orderDetail";
import {cancelOrderApi, getOrderPaymentQrApi, getOrderPayStatusApi} from "@/api/cart";
import {getLang} from "@/utils/lang";

export default {
  name: "order",
  data() {
    return {
      path: '',
      tabsIndex: '0',
      search: '',
      baseUrl: '',
      payId: 0,
      dialogVisibles: false,
      lang: getLang(),
      tabs: [
        {
          label: '所有订单',
          labelEn: 'All Order',
          value: '0'
        }, {
          label: '待付款',
          labelEn: 'To Be Paid',
          value: '1'
        }, {
          label: '待发货',
          labelEn: 'To Be deliver',
          value: '2'
        }, {
          label: '已发货',
          labelEn: 'To Be received',
          value: '3'
        }
      ],
      orderList: []
    }
  },
  created() {
    this.path = this.$route.path
    this.OrderListApi()
  },
  components: {contentBox, orderDetail},
  methods: {
    onTabs(i) {
      if (i == this.tabsIndex) return
      this.tabsIndex = i
      this.OrderListApi()
    },
    OrderListApi() {
      getOrderListApi({status: this.tabs[this.tabsIndex].value}).then(res => {
        this.orderList = res
        this.payId = res.id
      })
    },
    delOrder(data) {
      if (this.lang == 'en') {
        this.$confirm('This operation will cancel the order, do you want to continue?', 'tips', {
          confirmButtonText: 'confirm',
          cancelButtonText: 'cancel',
          type: 'warning'
        }).then(() => {
          cancelOrderApi({id: data.id}).then(res => {
            this.OrderListApi()
            this.$message({
              type: 'success',
              message: 'Cancel Success!'
            });
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: 'Deletion Cancelled'
          });
        });
      } else {
        this.$confirm('此操作将取消该订单, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          cancelOrderApi({id: data.id}).then(res => {
            this.OrderListApi()
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      }

    },
    detail(d) {
      this.$refs.orderDetailRef.openForm(d)
    },
    buyOrder(id) {
      getOrderPaymentQrApi({id: id}).then(res => {
        this.baseUrl = res.paymentUrl
        this.dialogVisibles = true
        this.payId = id
      })
    },
    getOrderStatus() {
      getOrderPayStatusApi({id: this.payId}).then(res => {
        if (res.status == 0) {
          this.$message({
            type: 'error',
            message: '订单未支付!'
          });
        } else {
          this.$message({
            type: 'success',
            message: '支付成功'
          });
          this.OrderListApi()
        }
      })
    },
    closeDio() {
      this.dialogVisibles = false
      this.OrderListApi()
    },
    getStatus(d) {
      switch (d) {
        case -1:
          return '已取消'
        case 0:
          return '未支付'
        case 1:
          return '待发货'
        case 2:
          return '已发货'
        default:
          return '未知状态'
      }
    },
    getStatusEn(d) {
      switch (d) {
        case -1:
          return 'Canceled'
        case 0:
          return 'Unpaid'
        case 1:
          return 'To Be Shipped'
        case 2:
          return 'Shipped'
        default:
          return 'Unknown Status'
      }
    }
  }
}
</script>

<style scoped>
.content {
  padding: 20px;
  box-sizing: border-box;
}

.top_tabs {
  display: flex;
}

.top_tabs_box {
  position: relative;
  padding: 8px 20px;
  background: #e5e5e5;
  margin: 0 2px;
  flex: 0 1 20%;
  text-align: center;
  font-size: 1px;
}

.top_tabs_line {
  width: 100%;
  height: 2px;
  background: #C81422;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.toolbar {
  display: flex;
  align-items: center;
  width: 300px;
  margin: 10px 0;
}

.qc {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
