import request from '@/utils/request'

export function loginApi(params) {
    return request.post(
        '/Account/login',
        params,
        {}
    )
}

export function routeApi() {
    return request.post(
        '/Account/routes',
        {},
        {}
    )
}

export function userApi() {
    return request.post(
        process.env.VUE_APP_API_HOST + '/user/detail',
        {},
        {}
    )
}

export function getAddressListApi() {
    return request.post(
        process.env.VUE_APP_API_HOST + '/address/list',
        {},
        {}
    )
}

export function getAddressCreateApi(data) {
    return request.post(
        process.env.VUE_APP_API_HOST + '/address/create',
        data,
        {}
    )
}

export function getAddressDeleteApi(data) {
    return request.post(
        process.env.VUE_APP_API_HOST + '/address/delete',
        data,
        {}
    )
}

export function getOrderListApi(data) {
    return request.post(
        process.env.VUE_APP_API_HOST + '/order/list',
        data,
        {}
    )
}

export function smsApi(data) {
    return request.post(
        process.env.VUE_APP_API_HOST + '/login/send-sms',
        data,
        {}
    )
}

export function mobileLoginApi(data) {
    return request.post(
        process.env.VUE_APP_API_HOST + '/login/mobile',
        data,
        {}
    )
}

export function sendEmailApi(data) {
    return request.post(
        process.env.VUE_APP_API_HOST + '/login/send-email',
        data,
        {}
    )
}

export function loginEmailApi(data) {
    return request.post(
        process.env.VUE_APP_API_HOST + '/login/email',
        data,
        {}
    )
}
